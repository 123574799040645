import { ROLE } from 'constants/app.constant';

import NotificationStore, {
    notificationModel
} from 'core/store/notificationStore/notificationStore';

/**
 * Debounce function to delay the execution of a given function.
 *
 * @template T - The type of the function to debounce.
 * @param {T} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds for the debounce.
 * @returns {(...args: Parameters<T>) => void} - The debounced function.
 */
export const useDebouncedWaitCallback = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timer: NodeJS.Timeout;
    return (...args: Parameters<T>): void => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const formatDate = (dateString: string, isTimeStamp: boolean = false): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return '-';
    }

    const formatDateDetails: Intl.DateTimeFormatOptions = {
        month: 'short', // Short month name (e.g., Jan)
        day: '2-digit', // Two-digit day (e.g., 01)
        year: 'numeric' // Full year (e.g., 2022)
    };

    if (isTimeStamp) {
        formatDateDetails['hour'] = '2-digit';
        formatDateDetails['minute'] = '2-digit';
        formatDateDetails['second'] = '2-digit';
    }

    // Format the date string
    return date.toLocaleDateString('en-US', formatDateDetails);
};
export const formatDateISO = (
    dateString: string,
    isTimeStamp: boolean = false,
    isEndOfDay: boolean = false
): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return '-';
    }

    if (isEndOfDay) {
        // Set the time to 23:59:59.999 for the end of the day
        date.setHours(23, 59, 59, 999);
    }

    if (isTimeStamp) {
        // Format the date string to ISO 8601 with time (e.g., 2024-05-25T16:30:00Z)
        return date.toISOString();
    } else {
        // Format the date string to ISO 8601 without time, in local time
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
};

interface Data {
    [key: string]: string;
}

interface PlainObject {
    [key: string]: any;
}

export const isDataEmpty = <T extends Data>(data: T, checkBoolean = false): boolean => {
    return Object.values(data).every((value) => {
        if (value === '' || value === null || value === undefined) {
            return true;
        }
        if (Array.isArray(value) && value.length === 0) {
            return true;
        }
        if (checkBoolean && typeof value === 'boolean' && !value) {
            return true;
        }
        return false;
    });
};
/**
 * Get Avatar name
 * @param name
 */
export const getAvatarName = (name: string = '') => {
    return name
        ? name
              .match(/\b(\w)/g)
              ?.splice(0, 2)
              ?.join('')
              .toLocaleUpperCase()
        : ''; // ['J','S','O','N']
};

export const toggleLoadingIndicator = (showLoader: boolean) => {
    const body = document.body;
    if (showLoader) {
        body.classList.add('api-loader');
    } else {
        body.classList.remove('api-loader');
    }
};

export const isDataDifferent = <T extends Data>(before: T, after: T): boolean => {
    // Get the keys of the objects
    const keys = Object.keys(before);

    // Check if the values for any key are different
    for (const key of keys) {
        if (before[key] !== after[key]) {
            return true;
        }
    }

    // If all values are the same, return false
    return false;
};

export function formatPhoneNumber(phoneNumber: string): string {
    // Remove all non-digit characters from the phone number
    const digitsOnly: string = phoneNumber.replace(/\D/g, '');

    // Format the digits into the desired pattern
    const countryCode: string = digitsOnly.slice(0, 2); // Assuming country code is always 2 digits
    const areaCode: string = digitsOnly.slice(2, 4);
    const firstPart: string = digitsOnly.slice(4, 7);
    const secondPart: string = digitsOnly.slice(7, 11);

    const formattedNumber: string = `+${countryCode}-${areaCode}-${firstPart}${secondPart}`;

    return formattedNumber;
}

// example ["test1","test1","test1"]  to "test1,test1,test1"
export function arrayTOCommaString(value: string[]): string {
    return value.join(',');
}
// example  "test1,test1,test1" to  ["test1","test1","test1"]
export function convertStringToArray(str: string): string[] {
    if (typeof str === 'string') {
        return str.split(',').map((item) => item.trim());
    }
    return str;
}

// convert date string to year-month-date string formate
export function formatDateYYMMDD(value: string): string {
    const originalDate = new Date(value);
    const year = originalDate.getFullYear();
    const month = ('0' + (originalDate.getMonth() + 1)).slice(-2); // Adding 1 to month because January is 0
    const day = ('0' + originalDate.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
}

export const formatCurrency = (price: number | string | null) => {
    if (typeof price === 'string') price = parseFloat(price) || 0;
    else price = price ?? 0;

    return price.toLocaleString('en', { minimumFractionDigits: 2 });
};
/**
 * Replace the last segment of a URL path with a new segment.
 * @param {string} url - The original URL.
 * @param {string} newSegment - The new segment to replace the last one.
 * @returns {string} The modified URL.
 */
export const replaceLastSegment = (url: string, newSegment: string) => {
    const segments = url.split('/');
    segments.pop(); // Remove the last segment
    segments.push(newSegment); // Add the new segment
    return segments.join('/');
};

export const formatDateWithTime = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return '-';
    }

    // Format the date string
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short', // Short month name (e.g., Jan)
        day: '2-digit', // Two-digit day (e.g., 01)
        year: 'numeric' // Full year (e.g., 2022)
    });

    // Format the time string
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit', // Two-digit hour (e.g., 12)
        minute: '2-digit', // Two-digit minute (e.g., 05)
        hour12: true // Use 12-hour clock format with AM/PM
    });

    // Concatenate date and time with the desired format
    return `${formattedDate} - ${formattedTime}`;
};

export const toNumberOrNull = (value: any): number | null => {
    return typeof value === 'number' && !isNaN(value) ? value : null;
};
export const toStringOrNull = (value: any): string | null => {
    if (value === null || value === undefined || value === '') {
        return null;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
        return null;
    }
    return String(value);
};

export const focusOnFirstError = () => {
    setTimeout(() => {
        const innerDivPos = document.querySelector('.form-control.is-invalid') as HTMLElement;
        const scrollTarget = document.getElementById('scrollTarget');

        if (innerDivPos && scrollTarget) {
            scrollTarget.scrollTo({ top: innerDivPos.offsetTop + 50, behavior: 'smooth' });
            innerDivPos.focus();
        }
    }, 100);
};

export const isStaffUser = (userRoleId: number, excludeSalesRep = false) => {
    const staffUser = [
        ROLE.ADMIN.roleId,
        ROLE.PROJECT_MANAGER.roleId,
        ROLE.FINANCE.roleId,
        ...(excludeSalesRep ? [] : [ROLE.SALES_REP.roleId])
    ];
    return staffUser.includes(userRoleId);
};

export const isContactUser = (userRoleId: number) => {
    const contactUser = [ROLE.CONTACT_MANAGER.roleId, ROLE.CONTACT_USER.roleId];
    return !!contactUser?.includes(userRoleId);
};

export const isVendorUser = (
    userRoleId: number,
    params: { excludeSalesRep?: boolean; excludeFinance?: boolean } = {}
) => {
    const { excludeSalesRep = false, excludeFinance = false } = params; // default values as false

    const vendorUserSet = new Set([
        ROLE.VENDOR_ADMIN.roleId,
        ROLE.VENDOR_PROJECT_MANAGER.roleId,
        ROLE.VENDOR_PROJECT_COORDINATOR.roleId,
        ...(excludeSalesRep ? [] : [ROLE.VENDOR_SALES_REP.roleId]),
        ...(excludeFinance ? [] : [ROLE.VENDOR_FINANCE.roleId])
    ]);

    return vendorUserSet.has(userRoleId);
};
export const isSalesRep = (userRoleId: number) => {
    // SalesRepo , VendorSalesRep
    const allSalesRep = [ROLE.SALES_REP.roleId, ROLE.VENDOR_SALES_REP.roleId];
    return !!allSalesRep?.includes(userRoleId);
};
export const isVendorSpecialistUser = (userRoleId: number) => {
    const vendorSpecialistUser = [ROLE.VENDOR_SPECIALIST.roleId];
    return !!vendorSpecialistUser?.includes(userRoleId);
};

export const isCMOrCUUser = (userRoleId: number) => {
    const contactUser = [ROLE.CONTACT_MANAGER.roleId, ROLE.CONTACT_USER.roleId];
    return contactUser.includes(userRoleId);
};

const isObject = (obj: unknown): obj is PlainObject => {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
};

const hasOwn = (obj: PlainObject, key: string): boolean => {
    return Object.prototype.hasOwnProperty.call(obj, key);
};

const sortObject = (obj: PlainObject): PlainObject => {
    if (obj === null || obj === undefined) {
        return {};
    }

    const sortedObj: PlainObject = {};
    Object.keys(obj)
        .sort((a, b) => a.localeCompare(b))
        .forEach((key) => {
            const value = obj[key];
            if (isObject(value)) {
                sortedObj[key] = sortObject(value);
            } else if (Array.isArray(value)) {
                sortedObj[key] = value
                    .slice() // Create a shallow copy of the array
                    .sort((a, b) => {
                        const strA = isObject(a) ? JSON.stringify(sortObject(a)) : String(a);
                        const strB = isObject(b) ? JSON.stringify(sortObject(b)) : String(b);
                        return strA.localeCompare(strB);
                    });
            } else {
                sortedObj[key] = value;
            }
        });
    return sortedObj;
};

const compareValues = (val1: any, val2: any): boolean => {
    if (isObject(val1) && isObject(val2)) {
        return deepEqualValuesOnly(val1, val2);
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
        return compareArrays(val1, val2);
    } else {
        return String(val1) === String(val2);
    }
};

const compareArrays = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (!compareValues(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
};

export const deepEqualValuesOnly = (obj1: PlainObject, obj2: PlainObject): boolean => {
    const sortedObj1 = sortObject(obj1);
    const sortedObj2 = sortObject(obj2);

    for (const key of Object.keys(sortedObj1)) {
        if (!hasOwn(sortedObj2, key)) {
            return false; // Key missing in obj2
        }
        if (!compareValues(sortedObj1[key], sortedObj2[key])) {
            return false;
        }
    }

    return true;
};

export const showToastMessage = (message: notificationModel) => {
    NotificationStore.showAlert(message);
};

/**
 * Saves the current scroll position of the element with the specified id.
 *
 * @param {string} [id='tableWrapper'] - The id of the element to save the scroll position from.
 * @returns {Object} An object containing the current scrollLeft and scrollTop positions.
 * @property {number} scrollLeft - The horizontal scroll position.
 * @property {number} scrollTop - The vertical scroll position.
 */
export const saveScrollPosition = (id: string = 'tableWrapper') => {
    const wrapper = document.getElementById(id);
    if (wrapper) {
        return {
            scrollLeft: wrapper.scrollLeft,
            scrollTop: wrapper.scrollTop
        };
    }
    return { scrollLeft: 0, scrollTop: 0 };
};

/**
 * Restores the scroll position of the element with the specified id.
 *
 * @param {Object} scrollPosition - An object containing the scroll positions to restore.
 * @param {number} scrollPosition.scrollLeft - The horizontal scroll position to restore.
 * @param {number} scrollPosition.scrollTop - The vertical scroll position to restore.
 * @param {string} [id='tableWrapper'] - The id of the element to restore the scroll position to.
 */
export const restoreScrollPosition = (
    scrollPosition: { scrollLeft: number; scrollTop: number },
    id: string = 'tableWrapper'
) => {
    const wrapper = document.getElementById(id);
    if (wrapper && (scrollPosition.scrollLeft !== 0 || scrollPosition.scrollTop !== 0)) {
        wrapper.scrollLeft = scrollPosition.scrollLeft;
        wrapper.scrollTop = scrollPosition.scrollTop;
    }
};

/**
 * Splits country code and phone number
 * @param phoneNumber
 * @returns
 */
export const splitCountryCodeAndPhoneNumber = (
    phoneNumber: string = ''
): { code: string; phone: string } => {
    if (!phoneNumber?.includes('-')) return { code: '', phone: phoneNumber };
    const [code = '', ...rest] = phoneNumber.split('-');
    const phone = rest.join('').replace(/-/g, ''); // Removes remaining hyphens
    return { code, phone };
};

/**
 * Get the domain name of the current URL.
 */
export const getDomainName = () => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
};

/**
 * Get the size of the window.
 */
export const getWindowSize = () => {
    const width = window.innerWidth;
    return width >= 1528 ? 100 : 80;
};

// Helper function to convert base64 to Blob
const base64toBlob = (base64Data: string): Blob => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf' });
};

const base64toBlobForExcel = (base64Data: string): Blob => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
};

// Helper function to download Blob
const downloadBlob = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
};

const initiateDownloadPDF = (pdfBase64: string, pdfName: string) => {
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement('a');
    const fileName = pdfName;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};

const downloadExcel = (templateName: string, binaryData: Blob) => {
    const fileName = templateName || 'download.xlsx';
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(binaryData);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const handleDownloadPngSvg = (previewImage: RequestInfo | URL, previewImageName: string) => {
    fetch(previewImage)
        .then((response) => response.blob())
        .then((blob) => {
            // Create a new Blob object using the response
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', previewImageName);

            // Append to the document body and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
            window.URL.revokeObjectURL(url); // Free up memory
        })
        .catch(console.error); // Handle errors if necessary
};

/**
 * Extracts words between curly braces from the given text.
 *
 * @param text - The text to extract words from.
 * @returns An array of words between curly braces.
 */
const extractWordsBetweenCurlyBraces = (text: string): string[] => {
    // Regular expression to match content between curly braces
    const regex = /\{([^}]+)\}/g;
    let match: RegExpExecArray | null;
    const results: string[] = [];

    // Iterate over all matches and extract the words
    while ((match = regex.exec(text)) !== null) {
        results.push(match[1]);
    }

    return results;
};

const utils = {
    base64toBlob,
    downloadBlob,
    initiateDownloadPDF,
    downloadExcel,
    handleDownloadPngSvg,
    extractWordsBetweenCurlyBraces,
    // users role check
    isStaffUser,
    isVendorUser,
    isVendorSpecialistUser,
    base64toBlobForExcel
};

export default utils;
