import HttpService from '../../../core/interceptor/api.handler';
import {
    DocumentUploadType,
    ExecuteFormalDocumentType,
    FormalDocumentUploadType,
    GetUploadedDocumentListResponse,
    ReplaceExistingDocumentType,
    ResponseDocumentType
} from '../types/fileUpload.types';

const httpService = new HttpService();

const getDocumentTypes = async (
    getDocumentTypesApiUrl: string
): Promise<ResponseDocumentType[]> => {
    try {
        const response: ResponseDocumentType[] = await httpService.get(`${getDocumentTypesApiUrl}`);
        return response;
    } catch (error) {
        console.error('Error fetching document types:', error);
        throw error;
    }
};

const getUploadedDocumentList = async (
    getFileListApiUrl: string
): Promise<GetUploadedDocumentListResponse> => {
    const params = { data: { hideLoader: true } };
    try {
        const response: GetUploadedDocumentListResponse = await httpService.get(
            `${getFileListApiUrl}`,
            params
        );
        return response;
    } catch (error) {
        console.error('Error fetching uploaded documents:', error);
        throw error;
    }
};

const isFileTypeUpdate = async (
    updateDocumentTypeApiUrl: string,
    documentId: number,
    fileId: number,
    documentTypeId: number
): Promise<any> => {
    try {
        const response = await httpService.put(
            `${updateDocumentTypeApiUrl}/${documentId}/${fileId}/${documentTypeId}`
        );
        return response;
    } catch (error) {
        console.error('Error updating document type:', error);
        throw error;
    }
};

const deleteTheDocument = async (
    deleteApiUrl: string,
    documentId: number,
    fileId: number
): Promise<boolean> => {
    try {
        const response: any = await httpService.delete(
            `${deleteApiUrl}?documentId=${documentId}&FileId=${fileId}`
        );
        if (response && response.length > 0) {
            return true; // Return true if documentInfo array is empty
        }
        return false; // Return false if response is not valid or documentInfo array is not present
    } catch (error) {
        console.error('Error deleting document:', error);
        throw error;
    }
};

const documentUpload = async (payloads: DocumentUploadType, postURL: string) => {
    const url = postURL;
    const data = payloads;
    const params = { data: { hideLoader: true } };
    try {
        const response = await httpService.post(url, data, params);
        return response;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const executeFormalDocument = async (payloads: ExecuteFormalDocumentType, postURL: string) => {
    const url = postURL;
    const data = payloads;
    const params = { data: { hideLoader: true } };
    try {
        const response = await httpService.post(url, data, params);
        return response;
    } catch (error) {
        console.error('Error executing formal document:', error);
        throw error;
    }
};

const formalDocument = async (payloads: FormalDocumentUploadType, postURL: string) => {
    const url = postURL;
    const data = payloads;
    const params = { data: { hideLoader: true } };
    try {
        const response = await httpService.post(url, data, params);
        return response;
    } catch (error) {
        console.error('Error posting formal document:', error);
        throw error;
    }
};
const replaceExistingFormalDocument = async (
    payloads: ReplaceExistingDocumentType,
    postURL: string
) => {
    const url = postURL;
    const data = payloads;
    const params = { data: { hideLoader: true } };
    try {
        const response = await httpService.post(url, data, params);
        return response;
    } catch (error) {
        console.error('Error replacing existing formal document:', error);
        throw error;
    }
};

const fileUploadService = {
    getDocumentTypes,
    getUploadedDocumentList,
    deleteTheDocument,
    isFileTypeUpdate,
    documentUpload,
    formalDocument,
    replaceExistingFormalDocument,
    executeFormalDocument
};

export default fileUploadService;
